<template>
  <!-- 详情与编辑、添加都放同一页 -->
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p class="fs-18 c-9 fw-7">基本信息</p>
      <el-form
        class="mt-24"
        label-width="100px"
        :rules="rules"
        :model="comm_form"
        ref="comm_form"
      >
        <el-form-item label="商品标题" prop="goodsName">
          <el-input
            class="form-input"
            placeholder="请输入商品标题"
            v-model="comm_form.goodsName"
          ></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <p class="fs-18 c-9 fw-7">详细信息</p>
        <el-form-item label="商品条形码" prop="goodsCode">
          <el-input
            class="form-input"
            placeholder="输入条形码"
            v-model="comm_form.goodsCode"
            :disabled="String(flag) == 'false'"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属门店" prop="storeId">
          <el-select
            disabled
            placeholder="选择所属门店"
            v-model="comm_form.storeId"
            class="form-input"
          >
            <el-option
              v-for="(item, index) in store_list"
              :key="index"
              :value="item.id"
              :label="item.storeName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="餐饮商户" prop="merchantId">
          <el-select
            v-model="comm_form.merchantId"
            filterable
            placeholder="请选择"
            class="form-input"
          >
            <el-option
              v-for="item in merchantList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原价" prop="originalPrice">
          <el-input
            placeholder="输入原价"
            class="form-input"
            v-model="comm_form.originalPrice"
          >
          </el-input>
        </el-form-item>
         <el-form-item label="团购价" prop="actualPrice">
          <el-input
            placeholder="输入团购价"
            class="form-input"
            v-model="comm_form.actualPrice"
          ></el-input>
        </el-form-item>
        <el-form-item label="库存" prop="stock">
          <el-input
            placeholder="输入商品库存"
            class="form-input"
            v-model.number="comm_form.stock"
          >
            <template slot="append">件</template>
          </el-input>
        </el-form-item>
        <el-form-item label="商品主图" prop="goodsImgs">
          <UploadPics
            :uploadType="'main'"
            :imgLimit="1"
            :imgSize="'400*400'"
            :imgsPreview="comm_form.goodsImgs"
            @getImgData="getImgData"
          />
        </el-form-item>
          <el-form-item label="商品辅图" prop="subImgs">
          <UploadPics
            :uploadType="'pics'"
            :imgLimit="5"
            :imgSize="'400*400，图片底部预留60px'"
            :imgsPreview="comm_form.subImgs"
            @getImgData="getImgData"
            exceedInfo="活动图片"
          />
        </el-form-item>
        <el-form-item label="核销截止日期" prop="writeOffEndTime">
          <el-date-picker
                  v-model="comm_form.writeOffEndTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择核销截止日期">
          </el-date-picker>
        </el-form-item>
       
        <el-form-item label="已购基数" prop="virtualNum">
          <el-input
            type="number"
            placeholder="输入已购基数"
            class="form-input"
            v-model="comm_form.virtualNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sortNum">
          <el-input
            placeholder="输入排序数字"
            class="form-input"
            v-model="comm_form.sortNum"
          ></el-input>
        </el-form-item>
      
        <p class="pd-20">团购说明</p>
        <el-form-item label="使用时间"  prop="useTime"  label-width="160px">
          <el-time-picker
            is-range
            v-model="comm_form.useTime"
            value-format='HH:mm'
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            default-value=""
            @change= "timePickerChange"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="使用规则" prop="useRule" label-width="160px">
          <el-input
            type="textarea"
            resize="none"
            :rows="4"
            placeholder="输入使用规则"
            class="form-input"
            v-model="comm_form.useRule"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品详情介绍">
          <tinymce v-model="comm_form.goodsDetail" :height="300" />
        </el-form-item>
        <!-- <el-form-item label="商品状态" prop="status">
          <el-radio v-model="comm_form.status" :label="1">上架</el-radio>
          <el-radio v-model="comm_form.status" :label="0">下架</el-radio>
        </el-form-item> -->
      </el-form>
      <!-- 按钮区域 -->
      <div class="flx-row ali-c js-fe mt-50">
        <el-button plain class="form-button" @click="$router.go(-1)"
          >取消</el-button
        >
        <el-button
          class="form-button"
          type="primary"
          @click.native.prevent="submit"
          >确定</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import UploadPics from "@/components/UploadPics";
import UploadPicsSmall from "@/components/UploadPics_small";
import {
  getStoreList,
  getTypeList,
} from "@/api/online_department_store/commodity_manage";
import {
  getProductDetail,
  subProduct,
  productUpShelf,
  productDownShelf,
  productDelete,
  getMerchantsList,
} from "@/api/repast/commodity_manage";
export default {
  components: {
    Tinymce,
    UploadPics,
    UploadPicsSmall,
  },
  data() {
    let myValidate = (rule, value, callback, num) => {
      //  console.log(rule)
      // console.log(value)
      // console.log(num)
     
      if (num == 1) {
        //大于0的正整数
        if (!value) {
          callback(new Error("不能为空"));
        } else if (!/^[1-9]\d*$/.test(value)) {
          callback(new Error("大于0的正整数"));
        } else {
          callback();
        }
      } else if(num=="virtualNum"){  //已购基数
           if(value<0) {
             callback(new Error("请输入大于等于0的整数"));
           }else {
              callback();
           }
      }
      
      else {
        //价格
      
        if (!/^\d+(\.\d{1,2})?$/.test(value)) {
          callback(new Error("不超过两位小数的数字"));
        } else if (value === undefined || value === null || value === "") {
          callback(new Error("不能为空"));
        } else {
          callback();
        }
      }
    };

    return {
      comm_form: {
        goodsName: "", //商品名
        goodsCode: "", //商品编号
        merchantId: "", //商户id
        storeId: "", //门店id
        originalPrice: null, //展示价格
        stock: null, //库存
        effectiveDay: "", //有效天数
        actualPrice: "", //团购价格
        virtualNum: "", //已购基数
        goodsImgs: "", //主图
        subImgs: "", //活动图
        // status: null, //上下架状态
        sortNum: "", //排序
        useTime: ["08:00:00","22:00:00"], //使用时间
        useRule: "", //使用规则
        goodsDetail: ``, //团购详情
        writeOffEndTime: ''
      },
      classificationList: [],
      merchantList: [],
      rules: {
        goodsName: [
          { required: true, message: "标题不能为空", trigger: "blur" },
        ],
        goodsCode: [
          { required: true, message: "编码不能为空", trigger: "blur" },
        ],
        storeId: [{ required: true, message: "门店不能为空", trigger: "blur" }],
        merchantId: [
          { required: true, message: "商户不能为空", trigger: "blur" },
        ],
        originalPrice: [
          { required: true, message: "原价不能为空", trigger: "change" },
          { validator: myValidate, trigger: "change" },
        ],
        stock: [
          { required: true, message: "库存不能为空", trigger: "change" },
          { validator: (x, y, z) => myValidate(x, y, z, 1), trigger: "change" },
        ],
        writeOffEndTime: [
          { required: true, message: "核销截止日期不能为空", trigger: "change" },
        ],
        actualPrice: [
          { required: true, message: "有效天数不能为空", trigger: "change" },
          { validator: myValidate, trigger: "change" },
        ],
        virtualNum: [
          { required: true, message: "已购基数不能为空", trigger: "change" },
           { validator: (x, y, z) => myValidate(x, y, z,"virtualNum"), trigger: "change" },
        ],
        goodsImgs: [
          { required: true, message: "商品主图不能为空", trigger: "blur" },
        ],
        subImgs: [
          { required: true, message: "活动图片不能为空", trigger: "blur" },
        ],
        status: [{ required: true, message: "请选择商品状态" }],
        sortNum: [
          { required: true, message: "排序不能为空", trigger: "change" },
          { validator: (x, y, z) => myValidate(x, y, z, 1), trigger: "change" },
        ],
        useTime: [
          { required: true, message: "使用时间不能为空", trigger: "change" },
        ],
        useRule: [
          { required: true, message: "使用规则不能为空", trigger: "change" },
        ],
      },
      type_list: [],
      store_list: [],
      mainImgStr: "",
      subImgStr: "",
      flag: null,
    };
  },
  created() {
    // 用于标识编辑或者新增        ，
    // let query = this.$route.query;
    this.flag = this.$route.query.flag;
    this.comm_form.storeId = parseInt(this.$route.query.storeId);
    if (this.flag == false || this.flag == "false") {
      this.refillData(this.$route.query.id);
    }
    this.getStores();
    this.getMerchantsListHandle();
  },
  mounted() {},
  methods: {
    timePickerChange(){
   // console.log(this.comm_form.useTime);
    },
    // 商品信息回填
    refillData(e) {
      // this.comm_form = obj;
      getProductDetail(e).then((res) => {
        let data = res.data.body;
        // data.storeId = (data.storeId && data.storeId.split(',')).map(item => Number(item))
        data.useTime = data.useTime.split("-");
        this.comm_form = data;
        // console.log(this.comm_form);
      });
    },
    handleChange(value) {
      //商品分类改变
      // console.log(value);
    },
    ruleChange(val) {
      // console.log(val);
    },
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.store_list = res.data.body.list;
      });
    },
    //获取商户列表
    getMerchantsListHandle() {
      getMerchantsList(this.comm_form.storeId).then((res) => {
        this.merchantList = res.data.body;
      });
    },
    getImgData(imgData, type) {
      if (type == "main") this.comm_form.goodsImgs = imgData.mainImg;
      if (type == "pics") this.comm_form.subImgs = imgData.subImgs;
    },
    // 确定提交
    submit() {
      this.$refs["comm_form"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        this.submitForm();
      });
    },
    submitForm() {
      //console.log(this.comm_form.writeOffEndTime);
      let data = JSON.parse(JSON.stringify(this.comm_form));
      data.useTime = data.useTime.join("-");
      subProduct(data, String(this.flag) == "false" ? 1 : 0).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.$router.go(-1);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.rulesBox >>> .el-tag {
  margin-right: 20px;
}
.autoWidth {
  width: 100%;
}
.inline {
  display: inline-block;
}
</style>
